import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/info.svg'
import _imports_1 from '@/assets/img/scan_qrcode.svg'


const _withScopeId = n => (_pushScopeId("data-v-446994be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "activePurchase" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "scan" }
const _hoisted_4 = {
  key: 0,
  src: _imports_1
}
const _hoisted_5 = { class: "stream" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_qr_scan = _resolveComponent("qr-scan")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _imports_0,
      class: "infoImage",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openInfoModal()))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        localizedKey: "app_Recati all’Infopoint dalle 9:00 alle 22:00, mostra gli scontrini e inquadra il QR Code.",
        text: "Recati all’Infopoint dalle 9:00 alle 22:00, mostra gli scontrini e inquadra il QR Code.",
        type: "h3"
      }),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.loaded)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_qr_scan, { callback: _ctx.callback }, null, 8, ["callback"])
        ])
      ]),
      _createVNode(_component_localized_text, {
        localizedKey: "app_15 punti in arrivo, baby!",
        text: "15 punti in arrivo, baby!",
        type: "p",
        class: "text"
      })
    ])
  ]))
}