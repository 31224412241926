
import { Options, Vue } from 'vue-class-component';
import { QrReceiptClient } from '@/services/Services';
import InfoModal from '../modals/infoModal.vue';
import QrScan from '@/components/QrScan.vue';
import ErrorModal from '@/modals/errorModal.vue';
import ActivePurchaseSuccessModal from '../modals/activePurchaseSuccessModal.vue';
import store from '@/store';

@Options({
    components: {
        QrScan
    }
})
export default class ActivePurchase extends Vue {

    loaded: boolean = false;

    created() {
        store.state.showSpinner++;
        setTimeout(() => {
            store.state.showSpinner--;
            this.loaded = true;
        }, 1000);
    }

    callback(data: string) {
        if(!data.trim())
            return;

        QrReceiptClient.claimReceipt(data)
        .then(x => {
            this.$opModal.show(ActivePurchaseSuccessModal, {
                callback: (route: string) => {
                    this.$opModal.closeLast();
                    this.$router.push(route);
                }
            })
        })
        .catch(y => {
            this.$opModal.show(ErrorModal, {
                showCloseButton: false,
                title: this.$localizationService.getLocalizedValue('app_QR Code non valido!', 'QR Code non valido!'),
                firstButtonText: this.$localizationService.getLocalizedValue('app_Riprova', 'Riprova'),
                callback1: () => {
                    window.location.reload();
                }
            })
        })
    }

    openInfoModal() {
        this.$opModal.show(InfoModal, {
            text: "Fai acquisti di qualsiasi importo nei negozi di Merlata Bloom Milano, porta gli scontrini all’Infopoint dalle 9:00 alle 22:00 e tramite questa webapp scansiona il QR Code che ti mostrerà la hostess. Con ogni scontrino scansionato ottieni ben 15 punti."
        })
    } 

}
